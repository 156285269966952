<!-- staff list -->
<template>
  <GgPage
    topType="location"
    pageType="2"
    title="Staff"
    @changeTopSelect="changeTopSelect"
  >
    <template v-slot:left>
      <div
        class="mar-l-b"
        v-if="
          $buttonPermission('Staff', 'Full') ||
          $buttonPermission('Staff', 'Edit') ||
          $buttonPermission('Staff', 'Create')
        "
      >
        <gg-create-button @click="openDialogAdd(null)" label="Add staff" />
      </div>
      <gg-flex-menus
        :menus="menuDatas"
        @select="menuSelect"
        :default-active="activeIndex"
      />
    </template>
    <edoovo-table
      height="100%"
      ref="multipleTable"
      :data="usersData.tableData"
      rowKey="id"
      :title="usersData.tableTitle"
      defaultValue="-"
      class="list-tableBox"
      v-loading="usersData.isLoading"
      @sortChange="_onSortChange"
      :sort="usersData.sort"
    >
      <!-- ===== -->
      <!-- 空状态 -->
      <!-- ===== -->
      <div slot="empty" style="text-align: center">
        <div
          style="
            width: 213px;
            text-align: center;
            border-radius: 50%;
            margin-bottom: 12px;
            line-height: 200px;
          "
        >
          <img
            style="width: 100%"
            :src="require('@/assets/images/products/noData.svg')"
          />
        </div>
        <p style="font-size: 22px">No staff yet.</p>
      </div>
      <!-- ======= -->
      <!-- 分页组件 -->
      <!-- ======= -->
      <edoovo-pagination
        slot="pagination"
        @size-change="_pageSizeChange"
        @current-change="_pageChange"
        :current-page="query.current"
        :page-size="query.size"
        :total="usersData.totalNum"
      />
      <!-- ====== -->
      <!-- 筛选组件 -->
      <!-- ====== -->
      <div v-if="filterRenderData.length" class="list-filterBox" slot="filter">
        <base-filter
          ref="tablefilter"
          @filterCallback="_filterCallback"
          :renderData="filterRenderData"
          :multKeys="multKeys"
        />
      </div>
      <!-- =========== -->
      <!-- 每一项column -->
      <!-- =========== -->
      <edoovo-table-column
        size="L"
        label="Name"
        class="user_name"
        prop="name"
        sortName="firstName"
        :getTooltipText="(row) => `${row.firstName} ${row.lastName}`"
        v-slot="{ row }"
      >
        <div class="table-cell-box" @click="linkTo(row)"
		style="display: flex;align-items: center;"
		>
          <head-img
            class="table-cell-img"
            :src="row.photo ? `${row.photo}?` : ``"
            :firstName="row.firstName"
            :lastName="row.lastName"
            line-height="32"
          />
          <span class="table-cell-name"
		  style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: block;"
            >{{ row.firstName }} {{ row.lastName }}</span
          >
        </div>
      </edoovo-table-column>
      <edoovo-table-column
        size="L"
        label="Email"
        prop="emailAddress"
        sortName="emailAddress"
        v-slot="{ row }"
        :getTooltipText="(row) => `${row.emailAddress}`"
      >
        {{ row.emailAddress }}
      </edoovo-table-column>
      <edoovo-table-column
        size="M"
        label="Phone number"
        prop="phone"
        sortName="phoneNumber"
        :getTooltipText="(row) => `${row.countryCode?row.countryCode:''} ${row.phoneNumber?row.phoneNumber:''}`"
        v-slot="{row}"
      >
        {{row.countryCode && row.phoneNumber?row.countryCode:'-'}} {{row.phoneNumber?row.phoneNumber:''}}
      </edoovo-table-column>
      <edoovo-table-column
        size="M"
        label="Working title"
        prop="jobTitle"
        sortName="jobTitle"
        v-slot="{ row }"
      >
        {{ row.jobTitle?row.jobTitle:'-' }}
      </edoovo-table-column>
      <edoovo-table-column
        size="M"
        label="Type"
        prop="workType"
        sortName="workType"
      />
      <!-- ====== -->
      <!-- action -->
      <!-- ====== -->
      <edoovo-table-column v-slot="{ row }" key="action" v-if='query.status!="archived" && query.status!=""'>
        <el-tooltip
          content="Edit"
          popper-class="edoovo-table-cell-tooltip"
          :visible-arrow="false"
          v-if="
            $buttonPermission('Staff', 'Full') ||
            $buttonPermission('Staff', 'Edit')
          "
        >
          <gg-submit-button @click="openDialogAdd(row)" v-if='row.status!="archived"'>
            Edit
          </gg-submit-button>
        </el-tooltip>
        <gg-dropdown
          v-if="
		  ($buttonPermission('Staff', 'Full') ||
            $buttonPermission('Staff', 'Edit'))&& row.status!='archived'
          "
		  
        >
          More
          <template slot="menus">
            <gg-dropdown-item
              v-if="
                $buttonPermission('Staff', 'Full') ||
                $buttonPermission('Staff', 'Edit')
              "
              @click="openDialogPwd(row)"
            >
              Reset password
            </gg-dropdown-item>
            <gg-dropdown-item
              v-if="$buttonPermission('Staff', 'Full')"
              @click="handleArchive(row)"
            >
              Delete
            </gg-dropdown-item>
         <!--   <gg-dropdown-item
              v-if="$buttonPermission('Staff', 'Full')"
              @click="handleDelete(row)"
            >
              Delete
            </gg-dropdown-item> -->
          </template>
        </gg-dropdown>
      </edoovo-table-column>
	  <edoovo-table-column v-slot="{ row }" key="action" v-else-if='query.status!="active"'>
		  <el-tooltip
		    content="Edit"
		    popper-class="edoovo-table-cell-tooltip"
		    :visible-arrow="false"
		    v-if="
		      $buttonPermission('Staff', 'Full') ||
		      $buttonPermission('Staff', 'Edit')
		    "
		  >
		    <gg-submit-button @click="openDialogAdd(row)" v-if='row.status!="archived"'>
		      Edit
		    </gg-submit-button>
		  </el-tooltip>
		  <gg-dropdown
		    v-if="
		    ($buttonPermission('Staff', 'Full') ||
		      $buttonPermission('Staff', 'Edit'))&& row.status!='archived'
		    "
		    
		  >
		    More
		    <template slot="menus">
		      <gg-dropdown-item
		        v-if="
		          $buttonPermission('Staff', 'Full') ||
		          $buttonPermission('Staff', 'Edit')
		        "
		        @click="openDialogPwd(row)"
		      >
		        Reset password
		      </gg-dropdown-item>
		      <gg-dropdown-item
		        v-if="$buttonPermission('Staff', 'Full')"
		        @click="handleArchive(row)"
		      >
		        <!-- Archive -->
				Delete
		      </gg-dropdown-item>
		  <!--    <gg-dropdown-item
		        v-if="$buttonPermission('Staff', 'Full')"
		        @click="handleDelete(row)"
		      >
		        Delete
		      </gg-dropdown-item> -->
		    </template>
		  </gg-dropdown>
	    <el-tooltip
	      content="Restore the staff"
	      popper-class="edoovo-table-cell-tooltip"
	      :visible-arrow="false"
	      v-if="
		  ( $buttonPermission('Staff', 'Full') ||
	        $buttonPermission('Staff', 'Edit'))
			&& row.status =='archived'
	      "
	    >
	      <gg-submit-button @click="handleActive(row)">
	        Restore
	      </gg-submit-button>
	    </el-tooltip>
	   
	  </edoovo-table-column>
    </edoovo-table>

    <!-- add user -->
    <BaseFormDialog1
      v-model="formDialogAdd.visible"
	  v-if='formDialogAdd.visible'
      :title="formDialogAdd.title"
      :data="formDialogAdd.dataList"
      textReminder="* indicates a required field"
      :renderPass="formDialogAdd.renderPass"
      :ref="formDialogAdd.ref"
    >
      <template slot="renderHtml" slot-scope="scope">
        <gg-input
          style="margin-bottom: 40px"
          v-if="scope.data.key === 'emailAddress'"
          :ref="scope.data.key"
          v-model="formDialogAdd.dataRender.emailAddress"
          :placeholder="scope.data.placeholder"
          :error="formDialogAdd.emailErrorObj"
        />
        <baseGGSelect
          v-else-if="scope.data.key === 'roleId'"
          v-model="formDialogAdd.dataRender.roleId"
          :placeholder="scope.data.placeholder"
          :key="scope.data.key"
          :ref="scope.data.key"
          class="float"
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </md-option>
        </baseGGSelect>
        <baseGGSelect
          v-else-if="scope.data.key === 'locationId'"
          :ref="scope.data.key"
          v-model="formDialogAdd.dataRender.locationId"
          :placeholder="scope.data.placeholder"
          class="float"
          md-dense
          multiple
        >
          <md-option
            v-for="(item, index) in scope.data.itemList"
            :key="index"
            :value="item.id"
          >
            {{ item.value }}
          </md-option>
        </baseGGSelect>
        <ResetPassword
          v-else-if="
            scope.data.key === 'password' &&
            formDialogAdd.title === formDialogAdd.add
          "
          :ref="scope.data.key"
          switchTxt="Send password link"
          :autoSend="formDialogAdd.dataRender.sendLink"
          :autofocus="false"
          @sendEmail="sendEmail"
          v-model="formDialogAdd.dataRender.password"
        ></ResetPassword>
      </template>
      <template slot="footer">
        <baseButton @click="handleAddCancel">Cancel</baseButton>
        <baseButton
          type="primary"
          :disabled="!formDialogAdd.isDisabled"
          @click="handleAddForm"
          >{{ formDialogAdd.submitBtnTxt }}</baseButton
        >
      </template>
    </BaseFormDialog1>

    <!-- resetpassword dialog -->
    <gg-Dialog
      :visible.sync="formDialogPwd.visible"
      :before-close="cancelResetPwd"
      width="580px"
      ref="resetPassword"
    >
      <div slot="title" style="font-weight: normal;">
        Reset password for
        <span class="title-bold">{{ formDialogPwd.name }}</span>
      </div>
      <div>
        <ResetPassword
          @sendEmail="resetByEmail"
          :autoSend="formDialogPwd.autoSend"
          v-model="formDialogPwd.data"
        ></ResetPassword>
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="cancelResetPwd">Cancel</gg-cancel-button>
        <gg-submit-button
          :disabled="formDialogPwd.isDisabled"
          @click="handleResetPwd"
          >Reset</gg-submit-button
        >
      </span>
    </gg-Dialog>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </GgPage>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { lightFormat } from "date-fns";
export default {
  name: "staff",
  components: {},
  watch: {
    "formDialogAdd.dataRender.emailAddress": function (res) {
      let isPass = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(res);
      if (res.length === 0) {
        this.formDialogAdd.emailErrorObj = {
          show: true,
          message: "Email required",
        };
      } else if (!isPass) {
        this.formDialogAdd.emailErrorObj = {
          show: true,
          message: "Email address is invalid",
        };
      } else {
        this.formDialogAdd.emailErrorObj = {
          show: false,
          message: "",
        };
      }
    },
    formDialogAdd: {
      deep: true,
      handler(res) {
        this.$nextTick(() => {
          const isForm = this.$refs.formDialogAdd.valid(); // 表单其他非插槽元素是否验证通过
          let isAuto = false;
          const isRender = res.slotData.every(
            // 插槽数据是否通过校验
            (item) => this.$refs[item] && !!this.$refs[item].value
          );
          let locationPass =
            this.$refs.locationId && this.$refs.locationId.value.length > 0;

          if (this.$refs.password) {
            if (
              this.$refs.password.autoSend ||
              !this.$refs.password.isError.show
            ) {
              isAuto = true;
            }
          } else {
            isAuto = true;
          }

          res.renderPass = res.isDisabled =
            isForm &&
            isAuto &&
            isRender &&
            locationPass &&
            !this.$refs.emailAddress.error.show;
        });
      },
    },
    "formDialogPwd.data": function (res) {
      this.formDialogPwd.isDisabled = res.length < 8;
    },
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
    }),
  },
  data() {
    return {
      query: {
        size: 20,
        current: 1,
        locationId: "",
		status:"active"
      },
      menuDatas: [
        {
          name: "Staff",
          key: "",
          active: true,
          children: [
            {
              name: "Active",
              key: "active",
            },
            {
              name: "Deleted",
              key: "archived",
            },
          ],
        },
      ],
      activeIndex: "active",
      usersData: {
        ref: "usersTable",
        totalNum: 0,
        sort: { name: "", type: "" },
        isLoading: false,
        tableTitle: "Active Staff",
        tableData: [],
      },
      filterRenderData: [
        { filterKey: "name", type: "input", title: "Name" },
        { filterKey: "emailAddress", type: "input", title: "Email" },
        { filterKey: "phoneNumber", type: "input", title: "Phone No." },
        {
          filterKey: "jobTitle",
          type: "radio",
          title: "Working title",
          radioData: [],
        },
        {
          filterKey: "workType",
          type: "radio",
          title: "Type",
          radioData: [],
        },
      ],
      multKeys: [],
      locationSelected: "",
      formDialogPwd: {
        visible: false,
        data: "",
        autoSend: true,
        name: "",
        id: "",
        isDisabled: false,
      },
      formDialogAdd: {
        ref: "formDialogAdd",
        visible: false,
        title: "",
        add: "Add staff",
        edit: "Edit staff",
        submitBtnTxt: "Add",
        renderPass: false,
        formData: {},
        isDisabled: true,
        dataRender: {
          emailAddress: "",
          roleId: "",
          locationId: [],
          password: "",
          sendLink: true,
        },
        emailErrorObj: {
          show: false,
          message: "",
        },
        slotData: ["roleId", "locationId"],
        dataList: [
          {
            placeholder: "First name *",
            key: "firstName",
            value: "",
            autofocus: true,
            float: true,
            reg: /[\S]/,
            errorText: "First name required",
          },
          {
            placeholder: "Last name *",
            key: "lastName",
            value: "",
            float: true,
            reg: /[\S]/,
            errorText: "Last name required",
          },
          {
            placeholder: "Email address *",
            key: "emailAddress",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Role *",
            key: "roleId",
            float: true,
            value: "",
            itemList: [],
            renderHtml: true,
          },
          {
            placeholder: "Location *",
            key: "locationId",
            value: [],
            itemList: [],
            renderHtml: true,
          },
          {
            placeholder: "Password *",
            key: "password",
            type: "password",
            value: "",
            sendLink: true,
            renderHtml: true,
          },
        ],
      },
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  methods: {
    // $buttonPermission(moduleName, buttonName) {
    //   let rolePermission = this.$store.state.user.rolePermission || [];
    //   let permissonArr = rolePermission.filter(
    //     (item) => item.indexOf(moduleName) > -1
    //   );
    //   return permissonArr.indexOf(`${moduleName}_${buttonName}`) > -1;
    // },
    getLocation() {
      let params = {
        userId: this.userId,
        dataLevel: "location",
        dataType: "location",
      };

      Ajax.post("/usermanage/setting/getList", params).then((res) => {
        // console.log(res);
        this.formDialogAdd.dataList.find(
          (item) => item.key === "locationId"
        ).itemList = res.data;
      });
    },
    getRoleList() {
      let params = {
        userId: this.userId,
      };

      Ajax.get("/usermanage/role/getRoleByBelongId", params)
        .then((res) => {
          this.formDialogAdd.dataList.find(
            (item) => item.key === "roleId"
          ).itemList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeTopSelect(value) {
      if (value === "all") {
        this.query.locationId = "";
        this.getData();
      } else {
        this.query.locationId = value;
        this.getData();
      }
	  sessionStorage.setItem(
	    "lastChoiceLocation",
	    this.query.locationId
	  );
	  // console.log(sessionStorage.getItem('lastChoiceLocation'))
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      this.query.status = key;
	 
      this.usersData.tableTitle = `${item.name} Staff`;
	  if(item.name=='Staff'){
		  this.usersData.tableTitle = `Staff`;
	  }
      this.getData();
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    getData() {
      this.usersData.isLoading = true;
      let params = Object.assign({}, this.query, {
        userId: this.userId,
      });
      Ajax.post("/usermanage/staff/pageList", params)
        .then((res) => {
          if (res.code === "0000") {
            this.usersData.isLoading = false;
            let { records, total } = res.data;
            this.usersData.tableData = records;
            this.usersData.totalNum = total;
          }
        })
        .catch((err) => {
          this.usersData.isLoading = false;
        });
    },
    _onSortChange(sort) {
      this.usersData.sort = sort;
      this.query.sortName = sort.name;
      this.query.sortType = sort.type;
      this.getData();
    },
    _pageChange(val) {
      this.query.current = val;
      this.getData();
    },
    _pageSizeChange(val) {
      this.query.size = val;
      this.getData();
    },
    _filterCallback(data) {
      let obj = {};
	  let locationId = this.query.locationId||''
      if (data.length == 0) {
        this.query = {
          size: 20,
          current: 1,
          status: "",
		  locationId:locationId
        };
      }

      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });

      this.query = {
        size: 20,
        current: 1,
        status: "",
		locationId:locationId
      };
      this.query = Object.assign(obj, this.query);
      this.getData();
    },
    linkTo(row) {
      // 把每条数据的userid存下来，parentinfo页面去取
      sessionStorage.setItem(
        "staffInfo",
        JSON.stringify({
          userId: row.userId,
          type: "1",
        })
      );
      this.$store.commit("SET_BREADCRUMDATA", [
        {
          routeName: "staff",
          name: "Staff",
        },
        {
          routeName: "staff",
          name: `${row.firstName} ${row.lastName}`,
        },
        {
          name: "Personal info",
        },
      ]);
      this.$router.push({ name: "staffPersonalInfo" });
    },
    resetByEmail(val) {
      this.formDialogPwd.autoSend = val;
      if (val || this.formDialogPwd.data.length > 8) {
        this.formDialogPwd.isDisabled = false;
      } else {
        this.formDialogPwd.isDisabled = true;
      }
    },
    openDialogPwd(row) {
      this.formDialogPwd.name = `${row.firstName} ${row.lastName}`;
      this.formDialogPwd.id = row.userId;
      this.formDialogPwd.autoSend = true;
      this.formDialogPwd.isDisabled = false;
      this.formDialogPwd.visible = true;
    },
    cancelResetPwd() {
      this.formDialogPwd.visible = false;
      this.formDialogPwd.data = "";
    },
    handleResetPwd() {
      let isAuto = this.formDialogPwd.autoSend ? "1" : "0";
      this.formDialogPwd.visible = false;
      Ajax.post("/usermanage/password/resetPasswd", {
        userId: this.formDialogPwd.id,
        newPasswd: this.formDialogPwd.data,
        isAuto: isAuto,
      })
        .then((res) => {
          if (res.code === "0000") {
            this.handleSnack("Password has been reset");
            this.formDialogPwd.data = "";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendEmail(val) {
      this.formDialogAdd.dataRender.sendLink = val;
    },
    fillData() {
      let obj = {};
      this.$refs.formDialogAdd.dataExceptRender.forEach((item) => {
        obj[item.key] = item.value;
      });

      this.formDialogAdd.formData = Object.assign(
        {},
        obj,
        this.formDialogAdd.dataRender
      );

      this.formDialogAdd.formData.isAuto = this.formDialogAdd.dataRender
        .sendLink
        ? "1"
        : "0";
    },
    openDialogAdd(row) {
      if (row == null) {
        this.formDialogAdd.title = this.formDialogAdd.add;
        this.formDialogAdd.submitBtnTxt = "Add";
        this.formDialogAdd.dataList.forEach((i) => {
          i.value = "";
        });

        this.formDialogAdd.dataRender = {
          emailAddress: "",
          roleId: "",
          locationId: [],
          password: "",
          sendLink: true,
        };
      } else {
        this.formDialogAdd.title = this.formDialogAdd.edit;
        this.formDialogAdd.submitBtnTxt = "Save";
        this.formDialogAdd.id = row.userId;
        this.formDialogAdd.dataList.forEach((i) => {
          if (row[i.key]) {
            i.value = row[i.key];
          }
        });

        Object.keys(this.formDialogAdd.dataRender).forEach((item) => {
          this.formDialogAdd.dataRender[item] = row[item];
        });

        this.formDialogAdd.dataRender.locationId = [];
        if (!!row.locations) {
          row.locations.forEach((location) => {
            this.formDialogAdd.dataRender.locationId.push(location.id);
          });
        }
      }
      this.formDialogAdd.visible = true;
    },
    handleAddCancel() {
      this.formDialogAdd.visible = false;
    },
    handleAddForm(res) {
      this.fillData();
      if (this.formDialogAdd.title === this.formDialogAdd.add) {
        this.handleAddSubmit();
      } else {
        this.handleEditSubmit();
      }
    },
    handleAddSubmit() {
      let params = {};
      params = Object.assign({}, this.formDialogAdd.formData, {
        userId: this.userId,
      });

      Ajax.post("/usermanage/staff/saveStaff", params)
        .then((res) => {
          if (res.code === "0000") {
            this.getData();
            this.handleSnack("Staff has been added");
            this.formDialogAdd.visible = false;
          } else {
            if (res.message === "Email already exists") {
              this.formDialogAdd.emailErrorObj = {
                show: true,
                message: res.message,
              };
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleEditSubmit() {
      let params = {};
      params = Object.assign({}, this.formDialogAdd.formData, {
        userId: this.formDialogAdd.id,
      });

      Ajax.post("/usermanage/staff/updateStaff", params)
        .then((res) => {
          if (res.code === "0000") {
            this.getData();
            this.handleSnack("Staff has been edited");
            this.formDialogAdd.visible = false;
          } else {
            if (res.message === "Email already exists") {
              this.formDialogAdd.emailErrorObj = {
                show: true,
                message: res.message,
              };
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleArchive(row) {
      this.$confirm(
        "Once deleted, the staff will move to deleted.",
        "Delete staff ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
		      showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/usermanage/staff/updateStatus", {
            companyId: row.companyId,
            userId: row.userId,
            status: "archived",
          })
            .then((res) => {
              if (res.code === "0000") {
                this.getData();
                this.handleSnack("Staff has been deleted");
              }
            })
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    },
	handleActive(row) {
	  this.$confirm(
	    "Once the staff is restored, you will be able to recover the staff data.",
	    "Restore staff ?",
	    {
	      cancelButtonText: "Cancel",
	      confirmButtonText: "Restore",
	      cancelButtonClass: "gg_cancel_button",
	      confirmButtonClass: "gg_submit_button",
	      customClass: "gg_confirm",
		    showClose: false,
	    }
	  )
	    .then(() => {
	      Ajax.post("/usermanage/staff/updateStatus", {
	        companyId: row.companyId,
	        userId: row.userId,
	        status: "active",
	      })
	        .then((res) => {
	          if (res.code === "0000") {
	            this.getData();
	            this.handleSnack("Staff has been restored");
	          }
	        })
	        .catch(() => {});
	    })
	    .catch((err) => {
	      console.log(err);
	    });
	},
    handleDelete(row) {
      this.$confirm(
        "Once deleted, the staff will not be able to access the app.",
        "Delete staff ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
		      showClose: false, 
        }
      )
        .then(() => {
          Ajax.post("/usermanage/staff/updateStatus", {
            companyId: row.companyId,
            userId: row.userId,
            status: "delete",
          })
            .then((res) => {
              if (res.code === "0000") {
                this.getData();
                this.handleSnack("Staff has been deleted");
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    getSettingList(dataType) {
      let params = {
        dataLevel: "staff",
        dataType: dataType,
        userId: this.userId,
      };

      Ajax.post("/usermanage/setting/getList", params)
        .then((res) => {
          let arr = [];
          res.data.forEach((item1) => {
            this.filterRenderData.forEach((item) => {
              let obj = {};
              if (item1.dataType === item.filterKey) {
                obj = {
                  key: item1.value,
                  value: item1.value,
                };
                arr.push(obj);
              }
            });
          });

          this.filterRenderData.find(
            (item) => item.filterKey === dataType
          ).radioData = arr;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    // this.getData();
    this.getSettingList("workType");
    this.getSettingList("jobTitle");
    this.getRoleList();
    this.getLocation();
	setTimeout(()=>{
		if(sessionStorage.getItem('delete')=='staff'){
			 this.handleSnack("Staff has been deleted");
			sessionStorage.setItem('delete','')
		}
	},1000)
  },onload(){
	  
  }
};
</script>
<style lang='less'>
//@import url(); 引入公共css类
/deep/.gg_input label{
		font-family: Roboto;
	}
/deep/.md-field label{
	font-family: Roboto;
}
</style>