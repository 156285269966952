var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GgPage',{attrs:{"topType":"location","pageType":"2","title":"Staff"},on:{"changeTopSelect":_vm.changeTopSelect},scopedSlots:_vm._u([{key:"left",fn:function(){return [(
          _vm.$buttonPermission('Staff', 'Full') ||
          _vm.$buttonPermission('Staff', 'Edit') ||
          _vm.$buttonPermission('Staff', 'Create')
        )?_c('div',{staticClass:"mar-l-b"},[_c('gg-create-button',{attrs:{"label":"Add staff"},on:{"click":function($event){return _vm.openDialogAdd(null)}}})],1):_vm._e(),_c('gg-flex-menus',{attrs:{"menus":_vm.menuDatas,"default-active":_vm.activeIndex},on:{"select":_vm.menuSelect}})]},proxy:true}])},[_c('edoovo-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.usersData.isLoading),expression:"usersData.isLoading"}],ref:"multipleTable",staticClass:"list-tableBox",attrs:{"height":"100%","data":_vm.usersData.tableData,"rowKey":"id","title":_vm.usersData.tableTitle,"defaultValue":"-","sort":_vm.usersData.sort},on:{"sortChange":_vm._onSortChange}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","margin-bottom":"12px","line-height":"200px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px"}},[_vm._v("No staff yet.")])]),_c('edoovo-pagination',{attrs:{"slot":"pagination","current-page":_vm.query.current,"page-size":_vm.query.size,"total":_vm.usersData.totalNum},on:{"size-change":_vm._pageSizeChange,"current-change":_vm._pageChange},slot:"pagination"}),(_vm.filterRenderData.length)?_c('div',{staticClass:"list-filterBox",attrs:{"slot":"filter"},slot:"filter"},[_c('base-filter',{ref:"tablefilter",attrs:{"renderData":_vm.filterRenderData,"multKeys":_vm.multKeys},on:{"filterCallback":_vm._filterCallback}})],1):_vm._e(),_c('edoovo-table-column',{staticClass:"user_name",attrs:{"size":"L","label":"Name","prop":"name","sortName":"firstName","getTooltipText":function (row) { return ((row.firstName) + " " + (row.lastName)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"table-cell-box",staticStyle:{"display":"flex","align-items":"center"},on:{"click":function($event){return _vm.linkTo(row)}}},[_c('head-img',{staticClass:"table-cell-img",attrs:{"src":row.photo ? ((row.photo) + "?") : "","firstName":row.firstName,"lastName":row.lastName,"line-height":"32"}}),_c('span',{staticClass:"table-cell-name",staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis","display":"block"}},[_vm._v(_vm._s(row.firstName)+" "+_vm._s(row.lastName))])],1)]}}])}),_c('edoovo-table-column',{attrs:{"size":"L","label":"Email","prop":"emailAddress","sortName":"emailAddress","getTooltipText":function (row) { return ("" + (row.emailAddress)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.emailAddress)+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Phone number","prop":"phone","sortName":"phoneNumber","getTooltipText":function (row) { return ((row.countryCode?row.countryCode:'') + " " + (row.phoneNumber?row.phoneNumber:'')); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.countryCode && row.phoneNumber?row.countryCode:'-')+" "+_vm._s(row.phoneNumber?row.phoneNumber:'')+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Working title","prop":"jobTitle","sortName":"jobTitle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.jobTitle?row.jobTitle:'-')+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Type","prop":"workType","sortName":"workType"}}),(_vm.query.status!="archived" && _vm.query.status!="")?_c('edoovo-table-column',{key:"action",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [(
            _vm.$buttonPermission('Staff', 'Full') ||
            _vm.$buttonPermission('Staff', 'Edit')
          )?_c('el-tooltip',{attrs:{"content":"Edit","popper-class":"edoovo-table-cell-tooltip","visible-arrow":false}},[(row.status!="archived")?_c('gg-submit-button',{on:{"click":function($event){return _vm.openDialogAdd(row)}}},[_vm._v(" Edit ")]):_vm._e()],1):_vm._e(),(
		  (_vm.$buttonPermission('Staff', 'Full') ||
            _vm.$buttonPermission('Staff', 'Edit'))&& row.status!='archived'
          )?_c('gg-dropdown',[_vm._v(" More "),_c('template',{slot:"menus"},[(
                _vm.$buttonPermission('Staff', 'Full') ||
                _vm.$buttonPermission('Staff', 'Edit')
              )?_c('gg-dropdown-item',{on:{"click":function($event){return _vm.openDialogPwd(row)}}},[_vm._v(" Reset password ")]):_vm._e(),(_vm.$buttonPermission('Staff', 'Full'))?_c('gg-dropdown-item',{on:{"click":function($event){return _vm.handleArchive(row)}}},[_vm._v(" Delete ")]):_vm._e()],1)],2):_vm._e()]}}],null,false,353199909)}):(_vm.query.status!="active")?_c('edoovo-table-column',{key:"action",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [(
		      _vm.$buttonPermission('Staff', 'Full') ||
		      _vm.$buttonPermission('Staff', 'Edit')
		    )?_c('el-tooltip',{attrs:{"content":"Edit","popper-class":"edoovo-table-cell-tooltip","visible-arrow":false}},[(row.status!="archived")?_c('gg-submit-button',{on:{"click":function($event){return _vm.openDialogAdd(row)}}},[_vm._v(" Edit ")]):_vm._e()],1):_vm._e(),(
		    (_vm.$buttonPermission('Staff', 'Full') ||
		      _vm.$buttonPermission('Staff', 'Edit'))&& row.status!='archived'
		    )?_c('gg-dropdown',[_vm._v(" More "),_c('template',{slot:"menus"},[(
		          _vm.$buttonPermission('Staff', 'Full') ||
		          _vm.$buttonPermission('Staff', 'Edit')
		        )?_c('gg-dropdown-item',{on:{"click":function($event){return _vm.openDialogPwd(row)}}},[_vm._v(" Reset password ")]):_vm._e(),(_vm.$buttonPermission('Staff', 'Full'))?_c('gg-dropdown-item',{on:{"click":function($event){return _vm.handleArchive(row)}}},[_vm._v(" Delete ")]):_vm._e()],1)],2):_vm._e(),(
		  ( _vm.$buttonPermission('Staff', 'Full') ||
	        _vm.$buttonPermission('Staff', 'Edit'))
			&& row.status =='archived'
	      )?_c('el-tooltip',{attrs:{"content":"Restore the staff","popper-class":"edoovo-table-cell-tooltip","visible-arrow":false}},[_c('gg-submit-button',{on:{"click":function($event){return _vm.handleActive(row)}}},[_vm._v(" Restore ")])],1):_vm._e()]}}])}):_vm._e()],1),(_vm.formDialogAdd.visible)?_c('BaseFormDialog1',{ref:_vm.formDialogAdd.ref,attrs:{"title":_vm.formDialogAdd.title,"data":_vm.formDialogAdd.dataList,"textReminder":"* indicates a required field","renderPass":_vm.formDialogAdd.renderPass},scopedSlots:_vm._u([{key:"renderHtml",fn:function(scope){return [(scope.data.key === 'emailAddress')?_c('gg-input',{ref:scope.data.key,staticStyle:{"margin-bottom":"40px"},attrs:{"placeholder":scope.data.placeholder,"error":_vm.formDialogAdd.emailErrorObj},model:{value:(_vm.formDialogAdd.dataRender.emailAddress),callback:function ($$v) {_vm.$set(_vm.formDialogAdd.dataRender, "emailAddress", $$v)},expression:"formDialogAdd.dataRender.emailAddress"}}):(scope.data.key === 'roleId')?_c('baseGGSelect',{key:scope.data.key,ref:scope.data.key,staticClass:"float",attrs:{"placeholder":scope.data.placeholder},model:{value:(_vm.formDialogAdd.dataRender.roleId),callback:function ($$v) {_vm.$set(_vm.formDialogAdd.dataRender, "roleId", $$v)},expression:"formDialogAdd.dataRender.roleId"}},_vm._l((scope.data.itemList),function(item,index){return _c('md-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):(scope.data.key === 'locationId')?_c('baseGGSelect',{ref:scope.data.key,staticClass:"float",attrs:{"placeholder":scope.data.placeholder,"md-dense":"","multiple":""},model:{value:(_vm.formDialogAdd.dataRender.locationId),callback:function ($$v) {_vm.$set(_vm.formDialogAdd.dataRender, "locationId", $$v)},expression:"formDialogAdd.dataRender.locationId"}},_vm._l((scope.data.itemList),function(item,index){return _c('md-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.value)+" ")])}),1):(
            scope.data.key === 'password' &&
            _vm.formDialogAdd.title === _vm.formDialogAdd.add
          )?_c('ResetPassword',{ref:scope.data.key,attrs:{"switchTxt":"Send password link","autoSend":_vm.formDialogAdd.dataRender.sendLink,"autofocus":false},on:{"sendEmail":_vm.sendEmail},model:{value:(_vm.formDialogAdd.dataRender.password),callback:function ($$v) {_vm.$set(_vm.formDialogAdd.dataRender, "password", $$v)},expression:"formDialogAdd.dataRender.password"}}):_vm._e()]}}],null,false,2672667872),model:{value:(_vm.formDialogAdd.visible),callback:function ($$v) {_vm.$set(_vm.formDialogAdd, "visible", $$v)},expression:"formDialogAdd.visible"}},[_c('template',{slot:"footer"},[_c('baseButton',{on:{"click":_vm.handleAddCancel}},[_vm._v("Cancel")]),_c('baseButton',{attrs:{"type":"primary","disabled":!_vm.formDialogAdd.isDisabled},on:{"click":_vm.handleAddForm}},[_vm._v(_vm._s(_vm.formDialogAdd.submitBtnTxt))])],1)],2):_vm._e(),_c('gg-Dialog',{ref:"resetPassword",attrs:{"visible":_vm.formDialogPwd.visible,"before-close":_vm.cancelResetPwd,"width":"580px"},on:{"update:visible":function($event){return _vm.$set(_vm.formDialogPwd, "visible", $event)}}},[_c('div',{staticStyle:{"font-weight":"normal"},attrs:{"slot":"title"},slot:"title"},[_vm._v(" Reset password for "),_c('span',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.formDialogPwd.name))])]),_c('div',[_c('ResetPassword',{attrs:{"autoSend":_vm.formDialogPwd.autoSend},on:{"sendEmail":_vm.resetByEmail},model:{value:(_vm.formDialogPwd.data),callback:function ($$v) {_vm.$set(_vm.formDialogPwd, "data", $$v)},expression:"formDialogPwd.data"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('gg-cancel-button',{on:{"click":_vm.cancelResetPwd}},[_vm._v("Cancel")]),_c('gg-submit-button',{attrs:{"disabled":_vm.formDialogPwd.isDisabled},on:{"click":_vm.handleResetPwd}},[_vm._v("Reset")])],1)]),_c('Snackbar',{attrs:{"visible":_vm.snackbarData.visible,"content":_vm.snackbarData.content},on:{"update:visible":function($event){return _vm.$set(_vm.snackbarData, "visible", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }